<template>
  <div id="app">

    <a-button id="logout" type="dashed" @click="logout">
      Logout
    </a-button>

    <a-tabs v-model:activeKey="tabKey" @change="handleTabChange">
      <a-tab-pane key="1" tab="Notebooks">
        <NotebookDashBoard :keycloak='keycloak' :isActive="activeKey === '1'"/>
      </a-tab-pane>
      <a-tab-pane key="2" tab="Clusters">
        <ClusterDashBoard :keycloak='keycloak' :isActive="activeKey === '2'"/>
      </a-tab-pane>
      <a-tab-pane v-if="admin" key="3" tab="Templates">
        <TemplateDashBoard :keycloak='keycloak' :isActive="activeKey === '3'"/>
      </a-tab-pane>
      <a-tab-pane key="4" tab="Testigos">
        <TestigosDashBoard :keycloak='keycloak' :isActive="activeKey === '4'"/>
      </a-tab-pane>
      <a-tab-pane key="5" tab="Clone">
        <CloneDashBoard :keycloak='keycloak' :isActive="activeKey === '5'"/>
      </a-tab-pane>
      <a-tab-pane key="6" tab="Sharing">
        <SharingDashBoard :keycloak='keycloak' :isActive="activeKey === '6'"/>
      </a-tab-pane>
      <a-tab-pane key="7" tab="DataLake">
        <DataLakeDashBoard :keycloak='keycloak' :isActive="activeKey === '7'"/>
      </a-tab-pane>
    </a-tabs>

  </div>

</template>

<script>


import ClusterDashBoard from "@/components/cluster/ClusterDashBoard.vue";
import {authEnabled} from "@/config/api";
import NotebookDashBoard from "@/components/notebook/NotebookDashBoard.vue";
import {clusterStore} from "@/store/ClusterStore";
import TemplateDashBoard from "@/components/templates/TemplateDashBoard.vue";
import {getClusters, getTemplates, getUsers} from "@/helpers/RefreshQueries";
import TestigosDashBoard from "@/components/testigos/TestigosDashBoard.vue";
import CloneDashBoard from "@/components/clone/CloneDashBoard.vue";
import SharingDashBoard from "@/components/sharing/SharingDashBoard.vue";
import DataLakeDashBoard from "@/components/datalake/DataLakeDashBoard.vue";


export default {
  name: 'app',
  components: {
    TestigosDashBoard,
    SharingDashBoard,
    CloneDashBoard,
    NotebookDashBoard,
    ClusterDashBoard,
    TemplateDashBoard,
    DataLakeDashBoard
  },
  props: ['keycloak'],
  setup() {
    const store = clusterStore()
    return {store}
  },
  data() {
    return {
      activeKey: null,
      tabKey: "1",
      admin: this.keycloak.realmAccess.roles.includes("zeppelin_admin")
    }
  },

  created() {
    getClusters(this.keycloak.token)
    getUsers(this.keycloak.token)
    getTemplates(this.keycloak.token)
  },
  mounted() {
    this.tabKey = "1"
  },
  methods: {
    logout() {
      if (authEnabled)
        this.keycloak.logout()
    },
    handleTabChange(newActiveKey) {
      //console.log('Tab changed to:', newActiveKey);
      this.activeKey = newActiveKey;
    }
  }

}
</script>


<style>


#app {
  margin: 20px 20px;
}

html::-webkit-scrollbar {
  display: none;
}

</style>
